button.ring-1.connectBtn,
select.ring-1.EthermBtn {
  white-space: nowrap;
  background: #1e3a8a;
  padding: 8px 10px;
  border-radius: 2rem;
  color: #fff;
  margin-right: 1.3rem;
  font-size: 20px;
}
 
.MuiButton-text.menubtn {
  min-width: 120px;
  white-space: nowrap;
  background: #1e3a8a;
  padding: 8px 10px;
  border-radius: 2rem;
  color: #fff;
  margin-right: 1.3rem;
}

ul.menu-list {
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
}
.navbar-tabs.main-menu {
  display: flex;
}
ul.ul-menu {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
}
ul.ul-menu li {
  margin-right: 1.3rem;
}

/*responsive header*/

@media screen and (max-width: 991px) {
  .mobileHamburger {
    width: 50% !important;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 5px !important;
    padding-top: 6px;
    float: left;
    margin-left: 0 !important;
  }
  .logo-section {
    width: 50% !important;
    display: flex;
    align-items: center;
    float: left;
  }
}

@media screen and (max-width: 991px) {
  ul.ul-menu {
    display: flex !important;
    width: 100% !important;
    clear: both !important;
    padding: 10px !important;
    align-items: center;
    justify-content: center;
  }

  .navbar-tabs.main-menu {
    display: block !important;
  }

  .user-menu a {
    font-size: 14px !important;
  }
  div#Monile-menu ul.ul-menu {
    display: none !important;
  }
  span.quantity-val {
    background-color: #fff !important;
    color: #040404 !important;
  }
  .accountDropBTN {
    font-size: 14px !important;
    padding: 5px !important;
  }
}
@media screen and (max-width: 390px) {
  .user-menu a {
    font-size: 12px !important;
  }
  button.ring-1.connectBtn {
    font-size: 12px !important;
  }
  select.ring-1.EthermBtn {
    font-size: 12px !important;
  }
  .accountDropBTN {
    font-size: 12px !important;
  }
  .user-menu a {
    margin-left: 0;
  }
}

.Menu-translator {
  margin-left: 20px;
}
.user-menu a {
  margin-left: 10px;
}
.user-menu ul li a {
  margin-left: 0 !important;
}
.user-menu .dropdown-menu li {
  margin-right: 0 !important;
}
.user-menu .dropdown-menu li:hover {
  background: #1e3a8a !important;
}
.user-menu .dropdown-menu li:hover a {
  color: #fff !important;
}
 
@media screen and (max-width: 600px) {
  .desktopUl_Menu button.ring-1.connectBtn,
  .desktopUl_Menu select.ring-1.EthermBtn {
    margin-right: 5px !important;
  }
}
/*my account dropdown*/
@media screen and (max-width: 1024px) {
  .dropdown-menu li {
    display: block !important;
  }
}
.user-menu .dropdown-menu {
  width: 82px;
  padding-right: 2px;
  margin: 0px;
}
.accountDropBTN {
  font-size: 20px;
  padding: 8px 17px;
  background: #fff !important;
  color: #000 !important;
}
/*translate*/
@media screen and (max-width: 991px) {
  .Menu-translator {
    position: absolute;
    top: 5px;
    right: 50px;
    display: block !important;
  }
}

@media screen and (max-width: 420px) {
  .Menu-translator {
    display: none !important;
  }
  .user-menu .dropdown-menu li {
    text-align: left;
}
.user-menu .dropdown-menu {
  width: auto!important;
  padding-right: 2px;
  margin: 0px;
}
}
.user-menu .dropdown-menu {
  width: auto;
}
.About{
  margin-right:0!important;
}