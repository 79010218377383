/* 05-10-2022 */

.left-side-custom {
  border-right-color: rgba(211, 220, 228, 1);
  width: 100%;
  max-width: 260px;
  margin-left: auto;
  height: 100vh;
}

.mar-left-auto {
  padding: 10px 15px;
  margin-top: 15px;
}

ul.ul-left-side {
  margin-top: 2rem;
  overflow: auto;
  height: 80vh;
}

ul.ul-left-side li a {
  padding: 10px 15px;
  display: block;
}

ul.ul-left-side li a {
  padding: 10px 15px;
  display: block;
  color: #767676;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.language-sec {
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.language-sec span {
  margin-left: 10px;
  font-size: 1rem;
  color: #767676;
}

.bg-grey-cs {
  background-color: #f5f7f9;
}

.inner-content {
  margin-left: 80px;
}

.inner-content h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 10px;
}

.main-tab-content {
  padding: 2rem 0px;
}

.content-inner {
  margin-top: 20px;
}

h5 {
  font-size: 18px;
  font-weight: 500;
}

ul.ul-inner li {
  list-style: disc;
  margin-bottom: 10px;
}

ul.ul-inner {
  padding-left: 15px;
}

.main-ul {
  margin-top: 18px;
}

ul.ul-inner li {
  list-style: disc;
  margin-bottom: 10px;
}

h4 {
  font-size: 26px;
  font-weight: 700;
}

ul.ul-left-side li a.active {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-right: 0;
  color: #e3970d;
}
button.button-custom {
  border: 1px solid #cdcdcd;
  width: 100%;
  height: 84px;
  text-align: right;
  padding: 10px 20px;
  border-radius: 5px;
}

button.button-custom p {
  font-weight: 500;
  color: #858585;
  font-size: 14px;
}

.left-btn-last {
  text-align: left !important;
}

.button-bottom {
  position: relative;
}

.button-bottom i.fas.fa-arrow-left {
  position: absolute;
  left: 22px;
  top: 32px;
  font-size: 20px;
}

.button-bottom i.fas.fa-arrow-right {
  position: absolute;
  right: 22px;
  top: 32px;
  font-size: 20px;
}
li.social a {
  color: #006adf !important;
  font-weight: 500;
}
h2.official-link {
  padding: 10px 15px;
  display: block;
  color: #767676;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
