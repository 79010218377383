.new {
  width: 100%;
  display: flex;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px;
      display: block;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .left {
        flex: 1;
        text-align: left;

        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .right {
        flex: 5;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: space-between;

          .formInputTerms {
            width: 100%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              font-weight: 900;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
            textarea {
              border-bottom: 1px solid black;
            }
          }

          .formInput {
            width: 40%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;
              font-weight: 900;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border: 1px solid gray;
              border-radius: 2px;
            }
            textarea {
              border-bottom: 1px solid black;
            }
          }

          .formHeading {
            width: 100%;
            h2 {
              font-size: large;
              font-weight: 900;
            }
          }

          button.btnsubmit {
            background-color: teal;
            border: none;
            color: white;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            width: 100%;
            max-width: 200px;
            max-height: 60px;
          }
        }
        .addTeam-form {
          display: unset;
          .formInput {
            margin-top: 20px;
            textarea {
              width: 100%;
            }
          }
        }
      }
      .editprofile .formInput input,
      .editprofile .formInput select {
        color: #6439ff;
      }
      select {
        width: 100%;
        padding: 5px;
        border: none;
        border-bottom: 1px solid gray;
      }
      .documentView {
        margin-left: 20px;
        margin-top: 8px;
      }
    }
  }
  .uploaded-images {
    display: flex;
    flex-wrap: wrap;
    .image {
      width: 70px;
      border: 1px solid #cbcbcb;
      margin: 2px;
      text-align: center;
      height: 70px;
      padding: 2px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// .image img {
//   width: 100px;
//   height: 100px;
//   object-fit: contain;
//   position: relative;
// }
.image {
  position: relative;
  margin: 1rem 0rem;
}
.images-Area {
  display: flex;
  align-items: center;
}
span.closeBtn {
  position: absolute;
  top: -18px;
  right: -6px;
  color: white;
  font-size: 0.8rem;
  background: red;
  padding: 3px 8px;
  border-radius: 2rem;
}
