@media screen and (min-width: 1150px) and (max-width: 1280px) {
  .full_Container {
    max-width: 98%;
  }

  .logo-section {
    display: flex;
    align-items: center;
  }

  ul.ul-menu li {
    margin-right: 15px;
  }

  ul.ul-menu li a,
  .accountDropBTN {
    font-size: 14px;
  }

  button.ring-1.connectBtn,
  select.ring-1.EthermBtn {
    font-size: 14px;
    padding: 8px;
  }

  span.quantity-val {
    top: -10px px;
    left: 10px;
  }
  
}

@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .full_Container {
    max-width: 98%;
  }

  .logo-section {
    display: flex;
    align-items: center;
  }

  .logo-section a img {
    height: 50px;
  }

  ul.ul-menu li {
    margin-right: 15px;
  }

  ul.ul-menu li a,
  .accountDropBTN {
    font-size: 14px;
  }

  button.ring-1.connectBtn,
  select.ring-1.EthermBtn {
    font-size: 14px;
    padding: 8px;
  }

  span.quantity-val {
    top: -10px px;
    left: 10px;
  }
  
}

@media screen and (min-width: 1024px) and (max-width: 1070px) {
  ul.ul-menu li {
    margin-right: 10px;
  }

  button.ring-1.connectBtn,
  select.ring-1.EthermBtn {
    font-size: 14px;
    padding: 8px;
    margin-right: 10px;
  }
  .img-heigh{
    height: 694px;
  }
}
 
@media screen and (min-width:1024px) {
  .width60 {
    width: 60%;
  }
  .img-heigh{
    height: 694px;
  }
}

@media screen and (max-width: 1024px) {
  .full_Container {
    max-width: 98%;
  }
  
  ul.desktopUl_Menu li {
    display: none;
  }

  .mobileHamburger {
    margin-left: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 25px!important;
  }

  .dBlock_Sec {
    display: block !important;
  }

  .dBlock_Sec .w100 {
    width: 100%
  }
  
}

@media screen and (max-width: 991px) {
  .desktopUl_Menu button.ring-1.connectBtn,
  .desktopUl_Menu select.ring-1.EthermBtn {
    font-size: 14px;
    padding: 5px;
  }
 
  .fa.fa-shopping-cart {
    font-size: 16px;
  }

  span.quantity-val {
    top: -10px;
    left: 10px;
  }

  .accountDropBTN {
    font-size: 14px;
    padding: 7px 10px;
  }

  /* .Menu-translator {
    display: none;
  } */
  .Menu-translator.mobilehde {
    display: none;
  }
  .mobileconnt {
    display: none;
  }
  ul.ul-menu {
    display: block;
  }
  ul.ul-menu li {
    display: none;
  }
  span.quantity-val {
    background-color: #000;
    color: #fff;
  }

  #property .image.h-full {
    height: 550px;
  }
  
}

@media screen and (max-width: 768px) {
  .full_Container {
    padding: 0px;
  }

  .mobileHamburger {
    margin-left: 0px;
  }

  .BTNBox {
    white-space: nowrap;
  }

  .team-image {
    height: 320px;
  }

  .team-image img {
    object-fit: contain;
  }
}
 
@media screen and (max-width: 600px) {
  .logo-section a img {
    height: 60px;
  }

  .desktopUl_Menu button.ring-1.connectBtn,
  .desktopUl_Menu select.ring-1.EthermBtn, .desktopUl_Menu li + button,  .desktopUl_Menu li + button + button {
    font-size: 12px !important;
    padding: 2px 4px;
  }

  .protContent_Box .blockSec {
    display: block;
    margin-top: 10px;
  }

  .protContent_Box .blockSec div {
    width: 50%;
    display: inline-block;
    margin-top: 15px;
  }

  .protContent_Box > p {
    font-size: 18px !important;
    margin-left: 0px;
    margin-top: 20px;
  }  

  .protContent_Box .blockSec div .ml-2 {
    margin-left:0px !important;
  }

  .chartSec {
    flex-direction: column !important;
  }

  .chartSec > div:nth-child(2) {
    width: 100% !important;
  }

  .property-sldier .images {
    height: 180px;
    overflow: hidden;
  }

  #property .image.h-full {
    height: 300px;
  }

 

}

.centerLine {
  border-right: 1px solid #a5a5a5;
}
.loaderspinner {
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}



@media screen and (max-width: 576px) {
.user-menu{
  text-align: right;
  display: flex;
}
.user-menu > a >span{
  padding: 0 !important;
}
.section-content {
  flex-direction: row;
}
.section-content > div:nth-child(2) {
  width: calc(100vw - 8rem);
  padding-right: 10px;
}
.section-content > div:nth-child(2) .inner-content {
  margin-left: 10px;
}
.section-content .inner-content div {
  width: 100%;
}
.section-content button.button-custom {
  width: 100% !important;
  height: 60px;
  padding: 10px;
}
.section-content .inner-content div > div {
  width: 50%;
  margin: 0;
}
.section-content .button-bottom {
  width: 100% !important;
 }
 /* button.button-custom p {
  font-size: 11px !important;
} */
.button-bottom i.fas.fa-arrow-right {
  position: absolute;
  right: 10px !important;
  top: 22px !important;
  font-size: 16px !important;
}
.button-bottom i.fas.fa-arrow-left {
  position: absolute;
  left: 10px !important;
  top: 22px !important;
  font-size: 16px !important;
}
ul.ul-left-side h1 {
  margin: 0 14px;
}
ul.ul-left-side {
  margin-top: 0.5rem !important;
 }
.left-side-custom {
  height: 65vh !important;
}
.accountDropBTN {
    font-size: 12px !important;
    padding: 2px 4px !important;
    white-space: nowrap;
}
}